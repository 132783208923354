.userpage-container{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color:red;
    display: flex;

}

a:link {
    text-decoration: none;
    color: #cc850a;
  }
  
  a:visited {
    text-decoration: none;
    color: #cc850a;
  }
  
  a:hover {
    text-decoration: none;
    color: #47a1ad;
  }
  
  a:active {
    text-decoration: none;
  }


.icons-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    margin-left: 50%;
}
/* .userdata-layout{
    background-color:gainsboro;
    border: 1px solid white;
    padding: 20px;
    border-radius: 20px;
    width: 80%;
    height: 700px;
    position: relative;
    margin: 0 auto;
    margin-top: 5%;
    overflow: hidden;
    display: block;
    align-items: center;
    text-align: center;
    caret-color: transparent;
    box-shadow: 0 8px 20px rgba(99, 99, 99, 0.19), 0 2px 2px rgba(148, 148, 148, 0.23);

} */

.left-container{
    background-color: #ffffff;
    width: 500px;
    
    height: 100%;
    align-items: center;
    text-align: center;
    color: white;
    /* margin: 20px auto; */
    vertical-align: top;
    display: flex;
    flex-direction: column;
        padding: auto;
    left:0;
    position: relative;
    color: black;
    box-shadow: 0 8px 20px rgba(161, 161, 161, 0.19), 0 2px 2px rgba(148, 148, 148, 0.23);

    
    

}

/* .left-container img{
    height: 150px;
    border-radius: 50%;
    border: 3px solid white;
    top: 0
} */


.energy-logo {
    position: relative;
    height: 200px;
    transform: translateY(-50%);
    top:  100px;
    
}

.icons-card{
    border-radius: 5px;
    box-shadow: 7px 7px 13px 0px rgba(206, 206, 206, 0.22);
    padding: 30px;
    margin: 20px;
    width: 250px;
    transition: all 0.3s ease-out;
    display: flex;
    justify-content:center;
    background-color: #e8ecef;
    border-radius: 12px;
    border: 5px solid #e8ecef;
     padding: 20px;
    border: 2px solid transparent; /* Start with a transparent border */
    border-radius: 8px; /* Optional: Add some rounding */
    text-align: center;
    /* transition: border-color 0.7s ease;  */
    cursor: pointer;



}

.icons-card:hover{
    transform: translateY(-5px);
    cursor: pointer;
    border-color: #f1f1f1; 
}

.user-img{
    position: relative;
    height: 100px;
    margin: 0 auto;
    transform: translateY(-50%);
    top:  150px;
    border-radius: 50%;
    border: 2px solid black;



}
.user-info{

    position: relative;
    height: 100px;
    margin: 0 auto;
    transform: translateY(-50%);
    top:  160px;
    border-radius: 50%;
    
}
.right-container{
    /* right: 0;
    align-items: center;
    text-align: center;
    margin: 50px;
    position: relative;
    display: flex; */
    background-color: #edf0f9;
    width: 100%;;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    height: 100%;
    align-items: center;
    text-align: center;
    color: white;
    /* margin: 20px auto; */
        padding: auto;
    right:0;
    position: relative;
    color: black;
    margin: 0 auto;
    box-shadow: 0 8px 20px rgba(161, 161, 161, 0.19), 0 2px 2px rgba(148, 148, 148, 0.23);
    
}
#adduser-button{
    height: 50px;
    width: 120px;
    background-color: rgb(29, 29, 29)
    ;
    color:white;
    font-size: 13px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
     
}

/* #adduser-button:hover{
    background-color:whitesmoke;
    color: black;
    border: 1px solid #e4e4e4;

    transition: .5s;
} */
.userpage-buttons-container{
    position: relative;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    

    
}
.userpage-buttons-container button{
    height: 50px;
    width: 120px;
    margin: 5px;
    background-color: rgb(29, 29, 29)
    ;
    color:white;
    font-size: 13px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;


}

/* .userpage-buttons-container button:hover{
    background-color:whitesmoke;
    color: black;
    border: 1px solid #e4e4e4;

    transition: .5s;
} */
