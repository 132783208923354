.add-academy-container{
position: absolute;
width: 100%;
height: 100%;
display: flex;
justify-content: space-around;
}


/* .add-academy-from{
    background-color:white;
    position: relative;
    left: 100px;
    top: 100px;
    height: 600px;
    width: 600px;
    display: block;
    margin: 0 auto;
    padding: 50px;

} */

.add-academy-from input{

    font-size: 14px;
    border-radius: 18px;

}

.add-academy-from select {
    width: 90px;
    text-align: center;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    height: 30px;
    border: 1px solid gainsboro;
}


.academy-day{
    position: relative;
    margin: 0 auto;
    width: auto;
}

.add-academy-from button{
    width: 100px;
    height: 40px;
    position: relative;
    margin-top: 50px;
}



.academy-price input{
    width: 100px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.academy-price{ 
    display: flex;
    text-align: center;
    align-items: center;
}


.css-b62m3t-control{
    
     margin-left: 0;
     margin-right: 0;
     width: 250px;
     margin: 0 auto;
}


.css-13cymwt-control{
    
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    width: 250px;
    margin: 0 auto;


}