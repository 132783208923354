.trainer-contract-page{
	display: flex;
	justify-content: space-around;
}

.trainer-contract-page-left{
	margin-top: 50px;
}
.trainer-contract-form-data{
	
	display: flex;
	justify-content: space-around;
}

.trainer-contract-form{
	border: 2px solid #cccccc;
	background-color: white;
	width: 1000px;
	height: 900px;
	padding: 10px;

}
.trainer-contract-form span {
	pointer-events: visible;
}

.ant-upload-wrappe{
	z-index: 9999;
}

.trainer-contract-form-left{
	z-index: inherit;
}
#outlined-basic:focus{
	background-color: white;
}


#demo-simple-select{
	color:"black";
	font-weight: bold;
}
.ant-picker-input > input::placeholder {
	font-weight: bold;
	color: black !important;}


.trainer-contract-page-right img{
	margin-top: 200px;
}

.ant-btn {
	background-color: #152238;
}
.ant-btn-disabled:hover {
	background-color: #152238;
}

.ant-btn span{
	color: white;
}

	@media only screen and (max-width: 600px) {
		.trainer-contract-form {
			width: 1000px;
			height: 900px;
			padding: 10px;
			margin-top: 300px;	
			}
			.trainer-contract-page{
				display: block;
			}

			.trainer-contract-page-right img{
				/* margin-left: -50%; */
			}
	  }