@import "@react-aria/example-theme";

.react-aria-DatePicker {
  color: var(--text-color);

  .react-aria-Group {
    display: flex;
    width: fit-content;
    align-items: center;
  }

  .react-aria-Button {
    background: var(--highlight-background);
    color: var(--highlight-foreground);
    border: 2px solid var(--field-background);
    forced-color-adjust: none;
    border-radius: 4px;
    border: none;
    margin-left: -1.929rem;
    width: 1.429rem;
    height: 1.429rem;
    padding: 0;
    font-size: 0.857rem;

    &[data-pressed] {
      box-shadow: none;
      background: var(--highlight-background);
    }

    &[data-focus-visible] {
      outline: 2px solid var(--focus-ring-color);
      outline-offset: 2px;
    }
  }

  .react-aria-DateInput {
    padding: 4px 2.5rem 4px 8px;
  }
}

.react-aria-Popover[data-trigger=DatePicker] {
  max-width: unset;
}

.finance-wraper{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 auto;
  gap: 20px;
}