body{
    font-family: 'Courier New', Courier, monospace;
}
  .dropdown-container {
    width: 300px;
  }

 



/* .css-b62m3t-container {
    width: 300px;
    border-radius: 5px;
    border:  1px solid   #d3d3d3;
    left: 80px;
    margin-bottom: 10px;


} */

.days-hours-drop-container{
    align-items: center;
    text-align: center;
   

}
.class-select {
    margin-bottom: 30px;
    width: 150px;
}



.add-class-page form {
 padding: 70px 20px ;
 border:  1px solid #d3d3d3;
 margin:   auto;
 top: 70px;


}


.add-class-page form button {
    width: 100px;
    height: 40px;
    border-radius: 7px;
    margin-top: 100px;
}
.add-class-page form button:hover {
    background-color: gray;
    color: black;
}
.add-class-page-input {
    border:  1px solid  #d3d3d3;
}

.classpage-container {
    /* width: 80%;
  height: 80%;
  margin: 50px auto;
  padding: 50px; */
  border: 1px solid gainsboro;
  /* border-radius: 20px;
  padding: 30px; */
  overflow-x: auto;
}


.classespage{
    width: 80%;
    margin:  80px auto;


}
 .classespage table{
    margin:  0 auto;
    filter: drop-shadow(0px 0px 10px rgba(150, 150, 150, 0.5));
}

#customers {
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td{
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  
  #customers tr:hover {background-color: #ddd;}
  


  .class-nav-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
    background-color: #cacaca69;
    height: 70px;
    align-items: center;
    text-align: center;
    padding: 10px;
    border-radius: 7px;
    width: 79%;
    filter: drop-shadow(0px 0px 10px rgba(150, 150, 150, 0.5));
    margin: 0 auto;
    position: relative;

  }

  .class-nav-container button {
    border:  none;
    background-color: #152238;
    color: white;
    height: 40px;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;

  }

  .class-nav-container button:hover{
    background-color:#bbbbbb;
    color: black;
  } 