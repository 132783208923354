
  

  
  .validation-from {
	max-width: 400px;
	margin: 200px auto;
	padding: 10px;
	height: 400px;
  }
  
  .reset-error-messsage {
	color: #bf1650;
  }
  
  .reset-error-messsage::before {
	display: inline;
	content: "⚠ ";
  }
  
  .validtion-input {
	display: block;
	width: 60%;
	border-radius: 4px;
	border: 1px solid white;
	padding: 10px 15px;
	margin-bottom: 10px;
	font-size: 14px;
	margin: 0 auto;
	margin-bottom: 30px;
	border: 1px solid #e4e4e4;
  }
  

  
  button[type="submit"],
  input[type="submit"] {
	background: #152238;
	color: white;
	border: none;
	margin-top: 40px;
	font-size: 16px;
	width: 200px;
	font-weight: 300;
  }

  button[type="submit"]:hover{
	background-color: none;
  }
  

  button[type="submit"]:active,
  input[type="button"]:active,
  input[type="submit"]:active {
	transition: 0.3s all;
	transform: translateY(3px);
	border: 1px solid transparent;
	opacity: 0.8;
  }
  

  

  
  button[type="submit"],
  input[type="button"],
  input[type="submit"] {
	-webkit-appearance: none;
  }
  

  /* button[type="button"] {
	display: block;
	appearance: none;
	background: #333;
	color: white;
	border: none;
	text-transform: uppercase;
	padding: 10px 20px;
	border-radius: 4px;
  } */
  
  /* hr {
	margin-top: 30px;
  }
   */
  /* button {
	display: block;
	appearance: none;
	margin-top: 40px;
	border: 1px solid #333;
	margin-bottom: 20px;
	text-transform: uppercase;
	padding: 10px 20px;
	border-radius: 4px;
  }
   */