* {
    font-family: 'Poppins', sans-serif;
    ;
}

.navbar-container{

    width: 100%;
    height: 50px;
    align-items: center;
    text-align: center;
    background-color: white;
    border-bottom: 1px solid whitesmoke;
    display: flex;
    padding-left: 40px;
    padding-right: 40px ;
    border-bottom: 1px solid gainsboro ;
    z-index: 999;
 position: fixed;
 justify-content:center;
 top: 0;
}
.navbar-container h3 {
    border: none;
    color: white;
    width: 80px;
    height: 30px;
    border-radius: 10px;
    position: absolute;

    margin: 100px;
    right: 0px;



}
.navbar-container label {
    font-weight:bold;
    font-size: 13px;
    cursor: pointer;
    margin-left: 12px;
    background-color: #cccccc;
    height: 30px;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    padding: 6px;
    width: 110px;
    display: flex;
    justify-content:center

}

.navbar-container label:hover{
    background-color:  #8d8d8d;
}



.home-label {
    display: flex;
    align-items: center;
    text-align: center;
    border: 1px solid black;
height: 30px;
padding: 6px;
border-radius: 5px;
cursor: pointer;
left: 20px;
position: absolute;

}

.home-label label{
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 300px) {
    .navbar-container{
        width: 300px;
        margin: 0 auto;
    }
  }


  .go-to-profile-button{
    width: 100%;
  }

  .go-to-profile-button:focus{
    outline: none; 
    background-color: #e4e4e4; 
    border-radius: 10px;
}
  