.ant-table-thead .ant-table-cell {
	background-color: #e4e4e4 !important;
	font-weight: bold !important;
	}

	.ant-table-cell{
		font-weight: bold !important;
		font-family: "poppins";
	}
.ant-table-container{
	border: 2px solid  #e4e4e4 !important;
}

.ant-table-cell #text{
	font-weight: bold  !important
}

a:hover{
	text-decoration: none;
}
a:active {
    color: green;
	text-decoration: none;

}


a:visited{
    color: black;
	text-decoration: none;
}
a:link{
    color: black;
	text-decoration: none;
}

.link {
	color: blue;
	text-decoration: none;
  }

  .link:focus {
	color: blue;
	text-decoration: none;
  }