@media print {
    .print-button {
      display: none !important;
    }
  }
.category-nav{
    border-radius: 30px;
}

.category-nav button:focus{
    background-color: white;
    color: #081534;
    border-radius: 12px;
    width: 150px;
}

.category-nav button:active{
    background-color: white;
    color: #081534;
    border-radius: 12px;
    width: 150px;
}
.category-nav button {
    background-color:#081534;
    border:none;
    color:white;
    font-weight:bold; 
    border-bottom:"12px";
    width: 150px;
     height:30px;
}
.customer-profile{
    position: absolute;
    height: 90%;
    width: 90%;
    background-color: #ececec;
    position: relative;
    margin: 40px auto;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 8px 20px rgba(99, 99, 99, 0.19), 0 2px 2px rgba(148, 148, 148, 0.23);

}

.top-container{
    /* top: 0;
    width: auto;
    background-color: whitesmoke;
    border: 1px solid gainsboro;
    margin: 10px auto;
    height: auto;
    align-items: center;
    text-align: center;
    padding: 10px;
    color: black;
    margin-bottom: 40px;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(99, 99, 99, 0.19), 0 2px 2px rgba(148, 148, 148, 0.23); */




}


.top-container img {
    height: 200px;
    border-radius: 10px;
    margin-bottom: 10px;
    border: 0.2px solid #004764;
}

.down-container{
    padding: 30px;
    width: auto;
    margin: 0 auto;
    background-color:  whitesmoke;
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 8px 20px rgba(99, 99, 99, 0.19), 0 2px 2px rgba(148, 148, 148, 0.23);

}




/* .col-4 button{
    background-color:#0072a0;
} */

/* .col-4 button:hover{
    background-color: #004764;
} */



.gym-package-form form {
  
    background-color:#fffafa;
    box-shadow: 0 8px 20px rgba(99, 99, 99, 0.19), 0 2px 2px rgba(148, 148, 148, 0.23);

    height: 40%;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    width: 320px;
    margin-bottom: 15px;

}

.gym-package-form form select {
    border: none;
    border-radius: 7px;
    height: 40px;
    background-color:black;
    color: white;
    text-align: center;
    padding: auto;
    margin-bottom: 10px;
    font-size: 14px;
    margin-left: 5px;
    width: 200px;
}


.gym-package-form form button {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    font-size: 13px;
}

.gym-package-form form option:hover{
    background-color: #004764;
}

