.add-gym-package {
    height: 100%;
    width: 100%;
    position: absolute;
}

.add-gym-package-form{
    margin: 100px auto;
    position: relative;
    -webkit-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
    -moz-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
    box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49); 
    display: block;
    
}


.add-gym-package-form select {
    border: 1px solid gainsboro;
    border-radius: 5px;
height: 40px;
}

.add-gym-package-form button {
    margin-top: 50px;
    width: 100px;
    background-color: #192841;
    text-align: center;
    align-items: center;
    height: 40px;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
    
}

.add-gym-package-form button:hover{
    background-color:darkgray;
    color: black;
    

}