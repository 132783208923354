.sidebar{
    width: 50%;
    height: 100%;
    background-color:lightgray;
    position: absolute;
    z-index: 999;
    overflow: hidden;
    right: 0;
    transition: width 1s, height 1s, transform 1s;
    margin-top: 50px;
    align-items: center;
    text-align: center;
    display: block;
    box-shadow: -60px 0px 100px -90px #000000, 60px 0px 100px -90px #000000;

}


.customerinfo-container{
    background-color:white;
    display: block;
    position: absolute;
    top:0;
    transform: translateX(50%);
    padding-left:.5em;
    padding-right:.5em;
    margin-left:auto;
    margin-right:auto;
    right:50%;
    padding: 50px;
    width: 400px;
    border-radius: 10px;
    margin-top: 10px;

    
}

.customerinfo-container button {
    width: 160px;
    height: 50px;
    border: none;
    border-radius: 7px;
    background-color: black;
    color: white;
    font-size: 20px;
    margin-top: 50px;
    font-weight: bold;
    cursor: pointer;

}

.customerinfo-container button:hover{
    background-color:lightgray;
    font-size: 20px;
    font-weight: bold;
color: black;
}

#remaining {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 15px;
    background: darkred;
    color: white;
    text-align: center;
    margin: 0 auto;
    font-size: 30px;

}

.custonerinfo-container img{
    border-radius: 9px;
    border: 3px solid gainsboro;
}



.hide-sidebar{

    width: 0;
    overflow: hidden;

}


.close-menu{
    left: 0;
    top: 0;
    position: absolute;

}

.active{
    right: 0;
  }

  .addRelative-form{
    margin: 0 auto;
    width: 400px;
    display: block;
    top: 70px
  }


.addRelative-form input {
    width: 200px;
    height: 35px;
}

.addRelative-form select {
    border: none;
    height: 30px;
    border-radius: 10px;
}
