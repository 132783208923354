.sidenav {
    height: 100%;
    width: 500px;
    position: fixed;
    z-index: 1000;
    top: 0;
    right:  0;
    background-color: gainsboro;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    -webkit-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
    -moz-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
    box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49); 
 }

 .classes-user-button{
  border-radius: 7px;
  border: 1px solid gainsboro;
  margin-bottom: 20px;
 }

 .PackageSidenav{
    height: 100%;
    width: 500px;
    position: fixed;
    z-index: 1000;
    top: 0;
    right:  0;
    background-color: gainsboro;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    -webkit-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
    -moz-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
    box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49); 
    padding: 20px;
 }

 .checkin-side-menu {

  height: 100%;
  width: 500px;
  position: fixed;
  z-index: 1000;
  top: 0;
  right:  0;
  background-color: gainsboro;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  -webkit-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
  -moz-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
  box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49); 
  padding: 20px;
 }

 .add-customer-toAcademy{
  height: 100%;
  width: 500px;
  position: fixed;
  z-index: 1000;
  top: 0;
  right:  0;
  background-color: gainsboro;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  -webkit-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
  -moz-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
  box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49); 
  padding: 20px;
 }

 .add-customer-toAcademy select {
  border: 1px solid gainsboro;
  width: 150px;
  background-color:#ececec;
  border-radius: 5px;
 }

 .filter-button {
  background-color: #192841;
  border: none;
  color: white;
  margin-bottom: 20px;
  height: 40px;
  border-radius: 7px;
  margin-right: 20px;
  font-size: 14px;
 }
 .ant-slider-tooltip {
  z-index: 9999 !important; /* Set a higher value for the tooltip */
}
 .add-customer-toAcademy{
  height: 100%;
  width: 500px;
  position: fixed;
  z-index: 1000;
  top: 0;
  right:  0;
  background-color: gainsboro;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  -webkit-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
  -moz-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
  box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49); 
  padding: 20px;
 }

 .add-customer-toAcademy-from{
  margin: 100px auto;
  height: 300px;
  width: 400px;
  padding: 20px;
  border: 1px solid #e4e4e4

 }


 .add-customer-toAcademy-from button {
  width: 150px;
  height: 40px;
  background-color: #192841;

 }

 .add-customer-toAcademy-from button:hover{ 
  background-color:black;
 }
 .PackageSidenav table {
    margin: 200px auto;
 }

 .sidenav form{
    margin: 0 auto;
    width: 400px;
    -webkit-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
    -moz-box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49);
    box-shadow: 0px 3px 15px rgba(100, 100, 100, 0.49); 
 }
  .sidenavColsed {

    background-color: rgba(0,0,0,0.4) ;
    position: relative;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    
  }

  .sidenavColsed a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }

.academies-page{
    width: 80%;
    height: 80%;
    margin: 50px auto;
    padding: 50px;
    border: 1px solid gainsboro;
    border-radius: 20px;
    padding: 30px;

}

.acdemies-buttons {
    display: flex;
    justify-content: space-evenly;
}

.loading-indicator{
    position: absolute;
    right: 50%;
}

.acdemies-buttons button{
    border: none;
    height: 40px;
    background-color: #192841;
    color: white;
    border-radius: 7px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
    width: 200px;
    margin-bottom: 10px;
}

.acdemies-buttons button:hover{
    background-color:brown;
}

.no-data{
    left: 50%;
    position: absolute;

}


#customers {
    border-spacing: 0;
    width: 100%;
    border-radius: "20px";
    font-family: "poppins";
        

    
  }
  
  #customers td, #customers th {
    padding: 8px;
    font-size: 15px;
    font-weight: bold;
    border-left: none;
    border-right: none;

  }
  

  
  #customers tr
 {background-color: #f2f2f2;
    text-align: center;
    margin-bottom: 20px;
border-radius: 10px}
   

#customers tr button {
    border: none;
    background-color:#192841 ;
    color: white;
    border-radius: 6px;
    font-size: 13px;
    height: 40px;
}

#customers tr button:hover{
    background-color: #192841 ;
}
  #customers tr:hover {background-color: gainsboro; color: black;}
  
  #customers th {
    padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color:#192841;
  text-align: center;
  

  color: white;
  font-size: 15px;
  font-weight: bold;
  

  }

  /* .css-b62m3t-container{
    margin: 0 auto;
    width: 250px;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  } */

  