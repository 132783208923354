

table td,
table th {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Libre Franklin', sans-serif;
  font-weight:600;


}

thead th {
  color: #fff;
}

.card {
  border-radius: .5rem;
  box-shadow: 0 8px 20px rgba(99, 99, 99, 0.19), 0 2px 2px rgba(148, 148, 148, 0.23);

}

.table-scroll {
  border-radius: .5rem;
}

.table-scroll table thead th {
  font-size: 1.25rem;
  background-color: #192841;
  color: white;
  text-align: center;
  align-items: center;

}
thead {
  top: 0;
  position: sticky;
  


}

.intro {
    margin-top: 30px;


}


.search-label {
    display: grid;
    grid-template: 1fr / auto 1fr;
    gap: 12px;
    
    
    border: 1px solid #CFD5DB;
    border-radius: 7px;
    background: #fafafa;
    padding: 12px;
    color: #6C757D;
    cursor: text;
    width: 300px;
    height: 50px;
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
    margin-left: 60px;
    
  }
  
  .search-label:focus-within {
    border: 1px solid #6C757D;
  }
  
  .search-label>input {
    outline: none;
    border: none;
    background: transparent;
  }



