.signin-form{
  background-color: white;
  border: 2px solid #e4e4e4;
  height: auto;
  padding: 20px;
  display: inline-block;
}


.ant-picker-input input{
  margin-bottom: 0;
  
}

.mainpage-container{
    position: absolute;
    width: 100%;
    height: 90%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
}



.vector-img{
 width: 500px;
 height: 500px;
 left: 0;
 position: relative;
 margin: 40px;

}



form {
  padding-top: 100px;
 align-items: center;
 text-align: center;
 background-color: white;
 width: 500px;
 height:700px;
 border-radius: 20px;
 position: relative;
 font-family: 'Poppins', sans-serif;
 display: block;
 align-content: space-around;
 background-color:whitesmoke;
 margin-left: 60px;
}

form label {
    font-size: 13px;
    font-weight: bold;
}
form input {
    width: 200px;
    margin-bottom: 40px;
    border: 1px solid gainsboro;
    height: 30px;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    align-items: center;
    text-align: center;
    


   }

   form input:focus {
    background-color: gainsboro;
    border: none;
    outline: none;

    
   }



.name-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}

.phone-container {
  align-items: center;
  justify-content: space-around;

}
form button {
    background-color: #1d1d1d;
    color: white;
    width: 80px;
    height: 30px;
    border: none;
    border-radius: 10px;
    position:relative;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    bottom:  0 ;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    margin-bottom: 20px;
   

   }

   form button:hover{
    background-color: black;
   }

/* form span{
    pointer-events: none;
    color: black;
    caret-color: transparent;
    border: none;


    

} */

   .select-container{
    display: flex;
    justify-content:space-around;
    margin: 0;
    border: none;
    padding: none;
   }

.select-container select {
    border: none;
    height: 30px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    border: 1px solid gainsboro;
    margin-bottom: 30px;
}
   .password-elemet{
    display: flex;
    justify-content: space-around;

    
   }
 span {
  color: black;
  font-weight: bold;
  z-index: 1000;
 }

   @media screen and (max-width: 500px) {
    .mainpage-container {
      display: grid;
    }
  }



  @media screen and (max-width: 500px) {
    .mainpage-container img{
      display: none;
    }
  }


  @media screen and (max-width: 500px) {
    .mainpage-container form{
        margin: 0 auto;
        padding: 60px;
        width: 250px;
        align-items: center;
        text-align: center;
        display: flex; /*added*/
        flex-direction: column; /*added*/
    }
  }

  @media screen and (max-width: 500px) {
    .mainpage-container form input{
        width: 120px;
        margin: 10px;

    }
  }


  @media screen and (max-width: 500px) {
    .mainpage-container form select{
        width: 100px;
        margin: 10px;

    }
  }


  @media screen and (max-width: 350px) {
    .mainpage-container form {
        background-color: red;
    margin-left: 140px;

    }
  }


  .customer-page-components{
    position: absolute;
    width: 100%;
    height: 90%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px;
    
  }

  
  

  .customer-page-components form {
    background-color: white;
    border: 1px solid #e4e4e4;
    padding: 20px;
    width: "400px";
    height: auto;
  }