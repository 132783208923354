.oneTimeSession-page{
   
    
}

.OTS-Nav{
    background-color: #203354;
    display: flex;
    width: 210px;
    padding: 5px;
    margin: 0 auto;
    border-radius: 13px;
    margin-top: 30px;
    justify-content:space-evenly;
    margin-bottom: 30px;
}

.OTS-Nav button {
    border: none;
    border-radius: 15px;
    background-color: #203354;
    color: white;
    font-size: 13px;
    padding-right: 10px;
    padding-left: 10px;
    transition: linear .4s ease-in;

}

.OTS-Nav button:focus {
    border: none;
    border-radius: 15px;
    background-color: white;
    color: black
}

#button1 {
    background-color: #203354;
    color: white;
    font-size: 13px;
    transition: all .5s;

}

/* #button1:hover {
background-color: ;} */

#button1:focus, #button1.active {
background-color: white;
color:  black;
}

#button2 {
    background-color: #203354;
    color: white;
    font-size: 13px;
    transition: all .5s;
}

/* #button2:hover {
background-color: red;} */

#button2:focus, #button2.active {
    background-color: white;
    color:  black;}

.add-ots-form {
        background-color: #f0f0f0;
         border: 1px solid white ;
         margin: 0 auto;
          height: auto;
           padding: 60px;
            display: block ;
           
    }

   